import React, { useEffect, useState, useRef } from "react";
import ContentPageLayout from "../../components/ContentPageLayout";
import { getCategories } from "../../api/content";
import { Link } from "gatsby";
import BackButton from "../../components/buttons/BackButton";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

const CategoryListPage = ({ suscription, location, section }) => {
  const [categories, setCategories] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageHeights, setImageHeights] = useState({});
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchRef = useRef();

  useEffect(() => {
    getCategories()
      .then((data) => {
        setCategories(data);
        setFilteredCategories(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener las categorías:", error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    const calculateHeights = () => {
      const heights = {};
      filteredCategories.forEach((category) => {
        if (category.desktopImage) {
          const aspectRatio = category.desktopImage.height / category.desktopImage.width;
          heights[category.id] = window.innerWidth * aspectRatio;
        }
      });
      setImageHeights(heights);
    };

    calculateHeights();
    window.addEventListener("resize", calculateHeights);
    return () => window.removeEventListener("resize", calculateHeights);
  }, [filteredCategories]);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCategories(categories);
    } else {
      setFilteredCategories(
        categories.filter((category) =>
          category.title.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
  }, [searchTerm, categories]);

  const handleOutsideClick = (e) => {
    if (
      searchRef.current &&
      !searchRef.current.contains(e.target) &&
      !searchTerm.trim()
    ) {
      setIsSearchOpen(false);
    }
  };

  useEffect(() => {
    if (isSearchOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [isSearchOpen]);

  return (
    <ContentPageLayout suscription={suscription} location={location} section={section}>
      <header className="p-8">
        <div className="flex items-center justify-between py-4">
          <BackButton fontSize={30} message="Categorías" />
          <div ref={searchRef} className="relative flex items-center">
            {!isSearchOpen && (
              <div
                className="cursor-pointer"
                onClick={() => setIsSearchOpen(true)}
              >
                <SearchIcon />
              </div>
            )}
            {isSearchOpen && (
              <div className="relative flex items-center bg-transparent w-full max-w-md">
                <input
                  type="text"
                  placeholder="Buscar categorías..."
                  className="bg-transparent border-b border-gray-400 w-full py-1 px-3 focus:outline-none"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  autoFocus
                />
                <button
                  className="absolute right-0 mr-2 text-gray-500"
                  onClick={() => {
                    setSearchTerm("");
                    setIsSearchOpen(false);
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      {isLoading ? (
        <div className="flex justify-center py-10">
          <p>Cargando categorías...</p>
        </div>
      ) : (
        <main className="py-8 space-y-6">
          {filteredCategories.map((category) => (
            <Link
              key={category.id}
              to={`/Category/${category.id}`}
              className="block w-full bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300"
            >
              <div className="relative w-full" style={{ height: `${imageHeights[category.id] || "auto"}px` }}>
                <img
                  src={category.desktopImage.url}
                  alt={category.title}
                  className="w-full h-full object-cover rounded-lg"
                  style={{ borderRadius: "20px" }}
                />
                <div
                  className="absolute inset-0 flex justify-center items-center"
                  style={{
                    backgroundColor: category.color || "rgba(0, 0, 0, 0.6)",
                    opacity: 0.6,
                    borderRadius: "20px",
                  }}
                ></div>
                <h2 className="absolute inset-0 flex justify-center items-center text-white text-2xl font-semibold text-center">
                  {category.title}
                </h2>
              </div>
            </Link>
          ))}
        </main>
      )}
    </ContentPageLayout>
  );
};

export default CategoryListPage;
